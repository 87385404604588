<template>
  <div v-html="getStatus()"></div>
</template>

<script>
import { presentIncidentDetailStatus } from "../../../../../../helpers/incident";

export default {
  name: "ProductsTableStatus",

  methods: {
    getStatus() {
      return presentIncidentDetailStatus(this.data);
    },
  },
};
</script>
